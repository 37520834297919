import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga';

import * as actionCreators from './homeActions'
import HomeNavbar from '../components/navbar/homeNavbar'
import Home from './home'
import AdminNavbar from '../components/navbar/adminNavbar'

class HomeContainer extends Component {

  async componentDidMount() {
    if(!this.props.User.uuid){
      this.props.history.push('/login')
    } else {
      this.props.actions.getClubs()
      this.props.actions.getClubSearch()
    }
  }

  render() {
    const { ClubList, User, history, actions } = this.props
    ReactGA.pageview('/home');
    if(User.role === 'standard'){
      return (
        <div className="home-container">
          <HomeNavbar 
            history = { history }
            pageName = "Home"
          />
          <Home 
            ClubList = { ClubList }
            User = { User }
            history = { history }
            actions={ actions }
          />
        </div>
      )
    } else {
      return (
        <div className="home-container">
          <AdminNavbar 
            history = { history }
            pageName = "Home"
          />
          <Home 
            ClubList = { ClubList }
            User = { User }
            history = { history }
            actions={ actions }
          />
        </div>
      )
    }
    
  }
}

const mapStateToProps = (state) => {
  return {
    ClubList: state.ClubList,
    User: state.User,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

HomeContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer))

export default HomeContainer
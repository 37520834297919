import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga';
import history from '../history'

import * as actionCreators from './adminActions'
import Admin from './admin'
import AdminNavbar from '../components/navbar/adminNavbar'

class AdminContainer extends Component {

  componentDidMount() {
    if(!this.props.User.uuid){
      history.push('/login')
    } else if (this.props.User.role === 'standard'){
      history.push('/home')
    } else {
      let uuid = this.props.User.adminUuid
      let token = localStorage.getItem('token')
      this.props.actions.getClub({uuid, token})
    } 
  }

  render() {
    const { SelectedClub, AdminClub, User, MatchList, Loading, actions } = this.props
    //ReactGA.initialize('UA-158640913-2');
    ReactGA.pageview('/admin');
    return (
      <div className="home-container">
        <AdminNavbar 
          history = { history }
          pageName = "Admin"
        />
        <Admin
          SelectedClub = { SelectedClub }
          AdminClub = { AdminClub }
          User = { User }
          MatchList = { MatchList }
          Loading = { Loading }
          history = { history }
          actions = { actions }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    SelectedClub: state.SelectedClub,
    AdminClub: state.AdminClub,
    MatchList: state.MatchList,
    Loading: state.Loading,
    User: state.User
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

AdminContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminContainer))

export default AdminContainer
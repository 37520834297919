import { createMuiTheme } from '@material-ui/core/styles';

export const Colors = {
  primary: '#089FDF',
  secondary: 'E18335'
}

const theme = createMuiTheme({
  palette: {
    primary: {main: '#089FDF'},
    secondary: {main: '#E18335'},
    success: {main: '#6A994E'},
    error: {main: '#DE3C4B'},
  },
  status: {
    danger: 'orange',
  },
});

export const muiThemeValues = {
  Colors
}

export const MaterialTheme = createMuiTheme(theme)
import assign from 'object-assign'

function ClubList(state = {
    clubs: [],
    request: '',
    club_search: []
}, action) {
    switch (action.type) {
        case "UPDATE_CLUB_LIST":
            return assign({}, state, {
                clubs: action.clubs,
            })
        case "CLEAR_CLUB_LIST":
            return assign({}, state, {
                clubs: [],
            })
        case "GET_CLUBS_REQUEST":
            return assign({}, state, {
              request: 'Submitted'
            })
        case "GET_CLUBS_FAILURE":
            return assign({}, state, {
              request: 'Failure'
            })
        case "GET_CLUBS_SUCCESS":
            return assign({}, state, {
                request: 'Success'
            })
        case "UPDATE_CLUB_SEARCH_LIST":
            return assign({}, state, {
                club_search: action.club_list
            })
        case "CLEAR_CLUB_SEARCH_LIST":
            return assign({}, state, {
                club_search: []
            })
        default:
            return state
    }
}

export default ClubList

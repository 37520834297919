import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga';
import history from '../history';

import * as actionCreators from './loginActions'
import Login from './login'

class LoginContainer extends Component {
  componentDidUpdate(prevProps, prevState) {
    if(this.props.User.uuid && !this.props.Signup.error){
      history.push('/home')
    }
  }

  render(){
    ReactGA.pageview('/login');
    return (
      <div className="login-container">
        <Login 
          User = {this.props.User}
          Signup = {this.props.Signup}
          history = {this.props.history}
          actions={this.props.actions}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.User,
    Signup: state.Signup,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginContainer)

import React from 'react';
import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ReactGA from 'react-ga';
import history from './history'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from "@material-ui/core/CssBaseline";
import { MaterialTheme } from './Pattern'
import './App.css';

import LoginContainer from './login/loginContainer';
import LogoutContainer from './logout/logoutContainer'
import SignupContainer from './signup/signupContainer'
import ForgotPasswordContainer from './forgotPassword/forgotPasswordContainer'
import ResetPasswordContainer from './resetPassword/resetPasswordContainer'
import HomeContainer from './home/homeContainer'
import ClubContainer from './club/clubContainer'
import AdminContainer from './admin/adminContainer'
import ProfileContainer from './profile/profileContainer';
import LoadingComponent from './components/loading/loading';

function App() {
  ReactGA.initialize('UA-158640913-2');
  return (
    <ThemeProvider theme = {MaterialTheme}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <LoginContainer />
          </Route>
          <Route exact path="/signup">
            <SignupContainer />
          </Route>
          <Route exact path="/forgot">
            <ForgotPasswordContainer />
          </Route>
          <Route exact path="/reset/:id">
            <ResetPasswordContainer />
          </Route>
          <Route exact path="/home">
            <HomeContainer />
          </Route>
          <Route exact path="/club">
            <ClubContainer />
          </Route>
          <Route exact path="/admin">
            <AdminContainer />
          </Route>
          <Route exact path="/profile">
            <ProfileContainer />
          </Route>
          <Route exact path="/logout">
            <LogoutContainer />
          </Route>
          <Route exact path="/loading">
            <LoadingComponent />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import { updateMatchList, clearMatchList } from '../match/matchActions'
import api from '../apiRoutes'

import { resetLoading } from '../components/loading/loadingActions'
export { setLoading } from '../components/loading/loadingActions'

export { clearMatchList }

export function updateSelectedClubUuid(uuid){
  return {
    type: 'UPDATE_SELECTED_UUID',
    uuid
  }
}

export function clearSelectedClubUuid(){
  return {
    type: 'CLEAR_SELECTED_UUID'
  }
}

export function updateSelectedClubInfo(club){
  return {
    type: 'UPDATE_CLUB',
    club
  }
}

export function clearSelectedClubInfo(){
  return {
    type: 'CLEAR_CLUB'
  }
}

export function updateRequestGoals(message){
  return {
    type: 'UPDATE_REQUEST_GOALS',
    message
  }
}

export function clearRequestGoals(){
  return {
    type: 'CLEAR_REQUEST_GOALS'
  }
}

export function getClub({uuid, token}) {
  let config = {
    method: 'GET',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' }
  }
  
  return dispatch => {
    return fetch(`${api}/club/uuid/${uuid}/match`, config)
      .then(response =>
        response.json().then(club => ({ club, response }))
      ).then(({ club, response }) =>  {
        dispatch(resetLoading())
        dispatch(updateSelectedClubInfo(club.clubResults))
        dispatch(updateMatchList(club.clubMatchs))
      })
  }
}

export function getAllClubs({token}) {
  let config = {
    method: 'GET',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `token=${token}`
  }

  return dispatch => {
    return fetch(`${api}/club/all`, config)
      .then(response =>
        response.json().then(clubList => ({ clubList, response }))
      ).then(({ clubList, response }) =>  {
      })
  }
}

export function createGoalRequest({uuid, token}) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `token=${token}&uuid=${uuid}`
  }

  return dispatch => {
    return fetch(`${api}/goal/match_uuid/create_goals`, config)
      .then(response =>
        response.json().then(createGoals => ({ createGoals, response }))
      ).then(({ createGoals, response }) =>  {
        dispatch(updateRequestGoals(createGoals.message))
      })
  }
}

export function uploadVideo({data, token}) {
  let config = {
    method: 'POST',
    // headers: { 'Content-Type':'multipart/form-data' },
    body: data
  }
  return dispatch => {
    return fetch(`${api}/goal/upload`, config)
      .then(response =>
        response.json().then(upload => ({ upload, response }))
      ).then(({ upload, response }) =>  {
        console.log('upload info: ', upload)
      })
  }
}

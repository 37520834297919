import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga';

import * as actionCreators from './clubActions'
import Club from './club'
import AdminNavbar from '../components/navbar/adminNavbar'
import HomeNavbar from '../components/navbar/homeNavbar'

class ClubContainer extends Component {

  componentDidMount() {
    if(!this.props.User.uuid){
      this.props.history.push('/login')
    } else {
      let uuid = this.props.SelectedClub.selected_uuid
      let token = localStorage.getItem('token')
      this.props.actions.getClub({uuid, token})
    } 
  }
  

  componentWillUnmount(){
    this.props.actions.clearSelectedClubUuid()
    this.props.actions.clearSelectedClubInfo()
    this.props.actions.clearMatchList()
    this.props.actions.clearRequestGoals()
  }

  render() {
    const { SelectedClub, User, MatchList, history, Loading, actions } = this.props
    ReactGA.pageview('/club');
    if(User.role === 'standard'){
      return (
        <div className="home-container">
          <HomeNavbar 
            history = { history }
            pageName = "Club"
          />
          <Club 
            SelectedClub = { SelectedClub }
            User = { User }
            MatchList = { MatchList }
            history = {history}
            Loading = { Loading }
            actions={ actions }
          />
        </div>
      )
    } else {
      return (
        <div className="home-container">
          <AdminNavbar 
            history = { history }
            pageName = "Club"
          />
          <Club 
            SelectedClub = { SelectedClub }
            User = { User }
            MatchList = { MatchList }
            history = {history}
            Loading = { Loading }
            actions={ actions }
          />
        </div>
      )
    }
    
  }
}

const mapStateToProps = (state) => {
  return {
    SelectedClub: state.SelectedClub,
    User: state.User,
    MatchList: state.MatchList,
    Loading: state.Loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

ClubContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ClubContainer))

export default ClubContainer
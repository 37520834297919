import React, { Component } from 'react';

import './loading.css'

export default class LoadingComponent extends Component {
    render(){
        return (
            <div className="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        )
    }
}
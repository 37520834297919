
export function updateMatchList(matchList){
  return {
    type: "UPDATE_MATCH_LIST",
    matchList
  }
}

export function clearMatchList(){
  return {
    type: "CLEAR_MATCH_LIST"
  }
}
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux'

import ClubList from '../home/homeReducer'
import SelectedClub from '../club/clubReducer'
import MatchList from '../match/matchReducer'
import User from './userReducer'
import Signup from '../signup/signupReducer'
import Loading from '../components/loading/loadingReducer';

export default combineReducers({
  ClubList,
  SelectedClub,
  MatchList,
  User,
  Signup,
  Loading,
  routing
});
import React, { Component } from 'react';

import './profile.css'
import { TextField, Button, FormControlLabel, Checkbox, Snackbar } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Profile extends Component  {
  constructor() {
    super();
    this.state = {
      favClub: '',
      password: '',
      passConfirmation: '',
      prodUpdates: true,
      feedback: false,
      changePasswordError: false,
      contribution: false,
      errorMessage: "Your confirmation password does not match",
      clubList: ''
    }
    this.submitProfileChanges = this.submitProfileChanges.bind(this)
    this.prodUpdateChange = this.prodUpdateChange.bind(this)
    this.feedbackChange = this.feedbackChange.bind(this)
    this.changePasswordError = this.changePasswordError.bind(this)
    this.contributionChange = this.contributionChange.bind(this)
  }

  componentDidMount() {
    const { User, ClubList } = this.props
    const club = ClubList.clubs
    this.setState({
      prodUpdates: User.prodUpdateNotification,
      feedback: User.prodFeedbackNotification,
      contribution: User.contribution
    })
    for(let i = 0; i < club.length; i++){
      if(club[i].uuid === this.props.User.favClubUuid){
        this.setState({
          favClub: club[i].name
        })
      }
    }
  }
  
  prodUpdateChange(){
    if(this.state.prodUpdates === true ){
      this.setState({prodUpdates: false})
    } else if (this.state.prodUpdates === false ){
      this.setState({prodUpdates: true})
    }
  }

  contributionChange(){
    if(this.state.contribution === true ){
      this.setState({contribution: false})
    } else if (this.state.contribution === false ){
      this.setState({contribution: true})
    }
  }

  feedbackChange(){
    if(this.state.feedback === true ){
      this.setState({feedback: false})
    } else if (this.state.feedback === false ){
      this.setState({feedback: true})
    }
  }

  submitProfileChanges(ev){
    if(this.state.password) { 
      if(this.state.password !== this.state.passConfirmation){
        return this.setState({changePasswordError: true})
      }
    }
    const favClub = document.getElementById('profile-fav-club-selection')
    const favClubName = favClub.value
    const clubs = this.props.ClubList.clubs
    let favClubUuid
    for(let i = 0; i < clubs.length; i++){
      if(favClubName === clubs[i].name){
        favClubUuid = clubs[i].uuid
      }
    }
    const password = this.state.password
    const productUpdates = this.state.prodUpdates
    const feedback = this.state.feedback
    const userUuid = this.props.User.uuid
    const contribution = this.state.contribution
    const token = localStorage.getItem('token')
    this.props.actions.updateUserProfile({password, token, productUpdates, feedback, userUuid, favClub: favClubUuid, contribution})
  }

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({changePasswordError: false});
  };
  
  changePasswordError(){
    if(this.state.changePasswordError === true){
      return (
        <div className="reset-password-error-message">
          <Snackbar open={true} autoHideDuration={2000} onClose={this.handleErrorClose}>
            <Alert onClose={this.handleErrorClose} severity="error">
              {this.state.errorMessage}
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }

  render (){
    const { ClubList, User } = this.props
    const clubs = ClubList.clubs
    return (
      <div className="profile-workspace">
        <div className="profile-fav-club">
          <h4>Favorite Club</h4>
          <div className="profile-fav-club-selection">
            <Autocomplete
              id="profile-fav-club-selection"
              freeSolo
              value={this.state.favClub}
              options={clubs.map((option) => option.name)}
              renderInput={(params) => (
                <TextField {...params} label="Search Clubs" margin="normal" variant="outlined" />
              )}
            />
          </div>
        </div>
        <div className="profile-change-password">
          <h4>Change Password</h4>
          <form >
          <div className="change-password">
              <div className="profile-change-password-form">
                <TextField className="profile-change-password-entry" label="New Password" type="password" margin="normal" variant="outlined" onChange={(e) => this.setState({password: e.target.value, changePasswordError: false})}/>
              </div>
              <div className="profile-change-password-confirmation-form">
                <TextField className="profile-change-password-entry" label="Confirm Password" type="password" margin="normal" variant="outlined" onChange={(e) => this.setState({passConfirmation: e.target.value, changePasswordError: false})}/>
              </div>
            </div>
          </form>
        </div>
        <div className="profile-email-notifications">
          <h4>Notifications</h4>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.prodUpdates}
                onChange={this.prodUpdateChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Product Updates"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.feedback}
                onChange={this.feedbackChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Willing to share your feedback"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.contribution}
                onChange={this.contributionChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Willing to potentially contribute to the project"
          />
        </div>
        <Button variant="contained" className="col" onClick={() => {this.submitProfileChanges(ev => ev)}} id={User.uuid + " profile-changes"} color="primary">
          Save Profile Settings
        </Button>
        {this.changePasswordError()}
      </div>
    );
  }
  
  
}

export default Profile;

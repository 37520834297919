// import setup from '../../dist/setup/setup'
// const api = setup.backend_api

import api from '../apiRoutes.js';
import { updateSignupError, updateUserInfo} from '../signup/signupActions'
import { getClubs } from '../home/homeActions'
export { GETRefreshToken, GETAccessToken } from '../actions/generalActions'

export {clearSignupError} from '../signup/signupActions'

export function test(){
  return {
      type: 'TEST_ACTION',
  }
}

export function adminUuid({adminUuid}){
  return {
    type: 'UPDATE_ADMIN_UUID',
    adminUuid
  }
}

export function clearUser(){
  return {
    type: 'CLEAR_USER_INFO'
  }
}

export function loginApi(values) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `password=${values.password}&email=${values.email}`
  }

  return dispatch => {
    return fetch(`${api}/user/login`, config)
      .then(response => response.json().then(login => ({ login, response })))
      .then(({ login, response }) =>  {
        if(!login.uuid){
          dispatch(updateSignupError(login.message))
        } else {
          dispatch(updateUserInfo(login))
          localStorage.setItem('token', login.token)
          dispatch(getClubs({token: login.token}))
          dispatch(adminUuid({adminUuid: login.adminUuid}))
        }
      })
  }
}


import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {main: '#DE3C4B'},
  },
});

class ErrorButtonOutlined extends Component {
  constructor() {
    super();
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  onSubmitHandler ({ ev, action, uuid, token }){
    action({ev, uuid, token})
  }
  
  render(){
    const { buttonId, buttonText, action, uuid, token } = this.props
    return (
      <ThemeProvider theme = {theme}>
        <Button variant="outlined" className="col" id={buttonId} onClick={(ev) =>{this.onSubmitHandler({ev, action, uuid, token})}} color="primary">
          {buttonText}
        </Button>
      </ThemeProvider>
      
    );
  }
}

export default ErrorButtonOutlined;

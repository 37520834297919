import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

import * as actionCreators from './profileActions'
import HomeNavbar from '../components/navbar/homeNavbar'
import AdminNavbar from '../components/navbar/adminNavbar'
import Profile from './profile'

class ProfileContainer extends Component {
  async componentDidMount() {
    if(!this.props.User.uuid){
      this.props.history.push('/login')
    } else {
      const refresh_token = localStorage.getItem('refresh_token')
      await this.props.actions.GETAccessToken({ refresh_token: refresh_token})
      const token = localStorage.getItem('access_token')
      this.props.actions.getClubs({ token })
    }
  }

  render(){
    const { User, ClubList, history, actions } = this.props
    ReactGA.pageview('/profile');
    if(User.role === 'standard'){
      return (
        <div className="profile-container">
          <HomeNavbar
            history = { history }
            pageName = "Profile"
          />
          <Profile 
            ClubList = { ClubList }
            User = { User }
            actions = { actions }
          />
        </div>
      )
    } else {
      return (
        <div className="profile-container">
          <AdminNavbar
            history = { history }
            pageName = "Profile"
          />
          <Profile 
            ClubList = { ClubList }
            User = { User }
            actions = { actions }
          />
        </div>
      )
    }
  }
  
}

const mapStateToProps = (state) => {
  return {
    User: state.User,
    Signup: state.Signup,
    ClubList: state.ClubList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default ProfileContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer))

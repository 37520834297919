import assign from 'object-assign'

function User(state = {
    uuid: '',
    role: '',
    favClubUuid: '',
    adminUuid: '',
    resetPasswordMessage: '',
    resetPasswordStatus: '',
    prodUpdateNotification: '',
    prodFeedbackNotification: '',
    contribution: '',
}, action) {
    switch (action.type) {
        case "UPDATE_USER_INFO":
            return assign({}, state, {
              uuid: action.uuid,
              role: action.role,
              favClubUuid: action.favclubuuid,
              prodUpdateNotification: action.prodUpdateNotification,
              prodFeedbackNotification: action.prodFeedbackNotification,
              contribution: action.contribution
            })
        case "CLEAR_USER_INFO":
            return assign({}, state, {
              uuid: '',
              role: '',
              favClubUuid: '',
              resetPasswordMessage: '',
              prodUpdateNotification: '',
              prodFeedbackNotification: '',
              contribution: ''
            })
        case "UPDATE_ADMIN_UUID":
            return assign({}, state, {
              adminUuid: action.adminUuid
            })
        case "CLEAR_ADMIN_UUID":
            return assign({}, state, {
              adminUuid: ''
            })
        case "UPDATE_RESET_PASSWORD_MESSAGE":
            return assign({}, state, {
              resetPasswordMessage: action.resetPasswordMessage,
            })
        case "CLEAR_RESET_PASSWORD_MESSAGE":
            return assign({}, state, {
              resetPasswordMessage: '',
            })
        case "RESET_PASSWORD_PENDING_STATUS":
            return assign({}, state, {
              resetPasswordStatus: 'Pending',
            })
        case "RESET_PASSWORD_COMPLETE_STATUS":
            return assign({}, state, {
              resetPasswordStatus: 'Complete',
            })
        case "RESET_PASSWORD_CLEAR_STATUS":
            return assign({}, state, {
              resetPasswordStatus: '',
            })
        default:
            return state
    }
}

export default User

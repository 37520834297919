import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

import * as actionCreators from './resetPasswordActions'
import ResetPassword from './resetPassword'

class ResetPasswordContainer extends Component {
  
  render(){
    const { actions, history, User } = this.props
    ReactGA.pageview('/reset/:id');
    return (
      <div className="reset-password-container">
        <ResetPassword
          actions = { actions }
          history = { history }
          User = { User }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.User
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default ResetPasswordContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer))

export function clearUserInfo(){
  return {
      type: 'CLEAR_USER_INFO',
  }
}

export function clearAdminUuid(){
  return {
    type: 'CLEAR_ADMIN_UUID',
  }
}
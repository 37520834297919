import api from '../apiRoutes'
export { getClub } from '../club/clubActions'

export { setLoading } from '../components/loading/loadingActions'

export function removeUpload({uuid, token}) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `uuid=${uuid}&token=${token}`
  }

  return dispatch => {
    return fetch(`${api}/goal/remove-submission`, config)
      .then(response =>
        response.json().then(removeUpload => ({ removeUpload, response }))
      ).then(({ removeUpload, response }) =>  {
        
      })
  }
}

export function approveSubmission({uuid, token}) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `uuid=${uuid}&token=${token}`
  }

  return dispatch => {
    return fetch(`${api}/goal/approve-submission`, config)
      .then(response =>
        response.json().then(approveSubmission => ({ approveSubmission, response }))
      ).then(({ approveSubmission, response }) =>  {
        
      })
  }
}

export const updateSubreddit = ({ club_uuid, subreddit, test_subreddit }) => {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `club_uuid=${club_uuid}&subreddit=${subreddit}&test_subreddit=${test_subreddit}`
  }

  return dispatch => {
    return fetch(`${api}/club/club_subreddit`, config)
      .then(response =>
        response.json().then(updateSubreddit => ({ updateSubreddit, response }))
      ).then(({ updateSubreddit, response }) =>  {
        console.log('updateSubreddit results: ', updateSubreddit.results)
      })
  }
}
import React, { Component } from 'react';

import './home.css'

const DEFAULT_CLUB_LOGO = 'https://b.fssta.com/uploads/application/bigboard/soccer/defaultTeamLogo.png'

const click = ({target, history, actions}) => {
  actions.updateSelectedClubUuid(target.id)
  history.push('/club')
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      searchTerm: "",
      searchParam: ["name"]
    };
  }

  search(items){
    return items.filter((club) => {
      return this.state.searchParam.some((newItem) => {
        return (
          club[newItem]
            .toString()
            .toLowerCase()
            .indexOf(this.state.searchTerm.toLowerCase()) > -1
        )
      })
    })
  }

  render () {
    const { ClubList, history, actions } = this.props
    const logo = ({logo}) => {
      if(logo === "undefined" ){ 
        return DEFAULT_CLUB_LOGO}
      return logo
    }
    return (
      <div className="home-workspace">
        <div className="home-results">
          <div className="home-title">
            <h2>Select a Club</h2>
          </div>
          <div className="home-search">
            <input
              type="search"
              name="search-form"
              id="home-club-search"
              className="home-club-search-input"
              placeholder="Search for a Club."
              autoComplete="off"
              value={this.state.searchTerm}
              onChange={(e) => this.setState({ searchTerm: e.target.value})}
            />
          </div>
          <div className="home-result-list">
            <ul className="row">
              {this.search(ClubList.clubs).map((club) => (
                <li id={club.uuid} key={club.uuid} onClick={(e) => click({target: e.target, history: history, actions: actions})}>
                  <article className="card" id={club.uuid} key={club.uuid}>
                    <div className="card-image" id={club.uuid}>
                      <img id={club.uuid} src={logo({logo: club.mediumlogo})} alt={club.uuid}/>
                    </div>
                    <div id={club.uuid} className="card-content">
                      <h3 id={club.uuid} className="card-name">{club.name}</h3>
                    </div>
                  </article>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Home;

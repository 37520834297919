import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import './forgotPassword.css'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ForgotPassword extends Component  {
  constructor() {
    super();
    this.state = {
      email: '',
      success: '',
    }
    this.submit = this.submit.bind(this)
    this.success = this.success.bind(this)
  }

  componentDidMount() {
    this.props.actions.clearResetPasswordMessages()
  }

  submit({email}){
    const { actions } = this.props
    actions.forgotPassword({email})
    this.setState({email: ''})
  }

  success(){
    if(this.props.User.resetPasswordMessage){
      return (
        <div className="forgot-password-success-message">
          <Snackbar open="open" autoHideDuration={2000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity="success">
              {this.props.User.resetPasswordMessage}
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }
  
  render (){
    const buttonText = "Forgot Password"
    const buttonId = "forgot-password-form-button-submit"
    return (
      <div className="forgot-password-workspace row">
        <div className="forgot-password-application-name col-12">
          <h2>Reddit Goalbot</h2>
        </div>
        <div className="forgot-password-raised-area">
          <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
          <form >
          <div className="forgot-password-form row ">
              <div className="forgot-password-form-username">
                <input className="forgot-password-username col-12" type="email" onKeyUp={(e) => this.setState({email: e.target.value})} placeholder="Email" />
              </div>
              <Button variant="contained" id={buttonId} color="primary" onClick={() => this.submit({ email: this.state.email })}>
                {buttonText}
              </Button>
            </div>
          </form>
        </div>
        {this.success()}
      </div>
    );
  }
  
  
}

export default ForgotPassword;

export const setLoading = () => {
    return {
      type: 'SET_LOADING'
    }
  }
  
  export const resetLoading = () => {
    return {
      type: 'RESET_LOADING'
    }
  }
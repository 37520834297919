import assign from 'object-assign'

function Signup(state = {
    error: '',
}, action) {
    switch (action.type) {
        case "UPDATE_SIGNUP_ERROR":
            return assign({}, state, {
              error: action.message,
              role: action.role,
            })
        case "CLEAR_SIGNUP_ERROR":
            return assign({}, state, {
              error: '',
            })
        default:
            return state
    }
}

export default Signup

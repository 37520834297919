import React, { Component } from 'react';
import { Snackbar } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import './login.css'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Login extends Component  {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      error: false,
    }
    this.submit = this.submit.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
  }

  componentDidMount() {
    this.props.actions.clearUser()
    // this.props.actions.GETRefreshToken()
  }
  

  componentDidUpdate(prevProps, prevState) {
    if(this.props.Signup.error && this.state.error === false){
      this.setState({error: true})
    }
  }

  async submit({email, password}){
    const { actions } = this.props
    // const refresh_token = localStorage.getItem('refresh_token')
    // await actions.GETAccessToken({ refresh_token: refresh_token})
    // const access_token = localStorage.getItem('access_token')
    const values = { email, password }
    actions.clearSignupError()
    actions.loginApi(values)
  }

  handleClose = (event, reason) => {
    
    if (reason === 'clickaway') {
      return;
    }
    this.props.actions.clearSignupError()
    this.setState({error: false});
  };

  error(){
    if(this.props.Signup.error){
      return (
        <div className="signup-error-message">
          <Snackbar open="open" autoHideDuration={6000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity="error">
              {this.props.Signup.error}
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }

  forgotPassword(ev){
    this.props.history.push('/forgot')
  }
  
  render (){
    const {history} = this.props
    const buttonText = "Submit"
    const buttonId = "login-form-button-submit"
    return (
      <div className="login-workspace row">
        <div className="login-application-name col-12">
          <h2>Reddit Goalbot</h2>
        </div>
        <div className="login-raised-area">
          <h3>Sign in to your account</h3>
          <form >
            <div className="login-form row ">
              <div className="login-form-username">
                <input className="login-username col-12" type="email" onKeyUp={(e) => this.setState({email: e.target.value})} placeholder="Email" />
              </div>
              <div className="login-form-password">
                <input className="login-password col-12" type="password" onKeyUp={(e) => this.setState({password: e.target.value})} placeholder="Password" />
              </div>
              <div className="login-forgot-password col-12" onClick={(ev) => {this.forgotPassword({ev, history})}}>
                <p>Forgot Password</p>
              </div>
              <Button variant="contained" id={buttonId} color="primary" onClick={() => this.submit({ email: this.state.email, password: this.state.password })}>
                {buttonText}
              </Button>
            </div>
          </form>
        </div>
        {this.error()}
      </div>
    );
  }
}

export default Login;

import assign from 'object-assign'

function SelectedClub(state = {
  selected_uuid: "",
  club: {},
  request_goals: '',
}, action) {
    switch (action.type) {
        case "UPDATE_CLUB":
          return assign({}, state, {
            club: action.club,
          })
        case "UPDATE_SELECTED_UUID":
          return assign({}, state, {
            selected_uuid: action.uuid
          })
        case "CLEAR_CLUB":
          return assign({}, state, {
            club: {},
          })
        case "CLEAR_SELECTED_UUID":
          return assign({}, state, {
            selected_uuid: ""
          })
        case "UPDATE_REQUEST_GOALS":
          return assign({}, state, {
            request_goals: action.message
          })
        case "CLEAR_REQUEST_GOALS":
            return assign({}, state, {
              request_goals: ''
            })
        default:
          return state
    }
}

export default SelectedClub

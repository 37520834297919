import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import * as actionCreators from './logoutActions'

class LogoutContainer extends Component {
  componentDidMount() {
    this.props.actions.clearUserInfo()
    this.props.history.push('/')
  }
  

  render(){
    return (
      <div className="logout-container">
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

LogoutContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutContainer))

export default LogoutContainer
import { updateResetPasswordMessage, resetPasswordCompleteStatus, resetPasswordPendingStatus } from '../forgotPassword/forgotPasswordActions'
import api from '../apiRoutes'
export { clearResetPasswordMessages, resetPasswordClearStatus } from '../forgotPassword/forgotPasswordActions'

export function resetPassword(values) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `password=${values.password}&token=${values.token}`
  }
  return dispatch => {
    dispatch(resetPasswordPendingStatus())
    return fetch(`${api}/email/reset_password`, config)
      .then(response =>
        response.json().then(resetPassword => ({ resetPassword, response }))
      ).then(({ resetPassword, response }) =>  {
        if(response.status === 400){
          dispatch(updateResetPasswordMessage(resetPassword.message))
        }
        dispatch(resetPasswordCompleteStatus())
      })
  }
}
import api from '../apiRoutes'
import { GETAccessToken } from '../actions/generalActions'
export { updateSelectedClubUuid, clearSelectedClubUuid } from '../club/clubActions'
export { GETAccessToken } from '../actions/generalActions'

export function updateClubState(clubs){
  return {
    type: 'UPDATE_CLUB_LIST',
    clubs
  }
}

export function clearClubState(){
  return {
    type: 'CLEAR_CLUB_LIST'
  }
}

export function getClubsRequest(){
  return {
    type: 'GET_CLUBS_REQUEST'
  }
}

export function getClubsFailure(){
  return {
    type: 'GET_CLUBS_FAILURE'
  }
}

export function getClubsSuccess(){
  return {
    type: 'GET_CLUBS_SUCCESS'
  }
}

export function updateClubSearchList(club_list){
  return {
    type: 'UPDATE_CLUB_SEARCH_LIST',
    club_list
  }
}

export function clearClubSearchList(){
  return {
    type: 'CLEAR_CLUB_SEARCH_LIST'
  }
}

export function getClubs() {
  let config = {
    method: 'GET',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' }
  }

  return dispatch => {
    dispatch(getClubsRequest())
    return fetch(`${api}/club/all`, config)
      .then(response =>
        response.json().then(Clubs => ({ Clubs, response }))
      ).then( async ({ Clubs, response }) =>  {
        if (response.status === 401) {
          const refresh_token = localStorage.getItem('refresh_token')
          await dispatch(GETAccessToken({refresh_token}))
          const newToken = localStorage.getItem('access_token')
          dispatch(getClubs({ token: newToken }))
        }
        if (Clubs !== []){
          dispatch(updateClubState(Clubs))
        } else {
          dispatch(getClubsFailure())
        }
      })
  }
}

export function getClubSearch(){
  let config = {
    method: 'GET',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' }
  }

  return dispatch => {
    dispatch(getClubsRequest())
    return fetch(`${api}/club/search`, config)
      .then(response =>
        response.json().then(ClubSearch => ({ ClubSearch, response }))
      ).then( async ({ ClubSearch, response }) =>  {
        dispatch(updateClubSearchList(ClubSearch.club_list))
      })
  }
}


import api from '../apiRoutes.js';
import { getClubs } from '../home/homeActions'
export { GETRefreshToken, GETAccessToken } from '../actions/generalActions'

export function updateUserInfo(value){
  return {
    type: "UPDATE_USER_INFO",
    uuid: value.uuid,
    role: value.role,
    favclubuuid: value.favclubuuid,
    prodUpdateNotification: value.prodUpdateNotification,
    prodFeedbackNotification: value.prodFeedbackNotification,
    contribution: value.contribution
  }
}

export function updateSignupError(message){
  return {
    type: "UPDATE_SIGNUP_ERROR",
    message
  }
}

export function clearSignupError(){
  return {
    type: "CLEAR_SIGNUP_ERROR",
  }
}

export function signupApi(values) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `email=${values.email}&password=${values.password}&access_token=${values.access_token}`
  }

  return dispatch => {
    return fetch(`${api}/user/signup`, config)
      .then(response => response.json().then(signup => ({ signup, response })))
      .then(({ signup, response }) =>  {
        if(!signup.uuid){
          dispatch(updateSignupError(signup.message))
        } else {
          dispatch(updateUserInfo(signup))
          dispatch(getClubs({token: signup.token}))
        }
      })
  }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

import * as actionCreators from './signupActions'
import Signup from './signup'

class SignupContainer extends Component {
  componentDidUpdate(prevProps, prevState) {
    if(this.props.User.uuid && !this.props.Signup.message){
      this.props.history.push('/home')
    }
  }
  
  render(){
    const { actions } = this.props
    ReactGA.pageview('/signup');
    return (
      <div className="signup-container">
        <Signup
          Signup = { this.props.Signup }
          actions = { actions }
        />
      </div>
    )
  }
  
}

const mapStateToProps = (state) => {
  return {
    User: state.User,
    Signup: state.Signup,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default SignupContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupContainer))

import React, { Component } from 'react';

import './homeNavbar.css'

class HomeNavbar extends Component {
  constructor() {
    super();
    this.homeNavigation = this.homeNavigation.bind(this)
    this.profileNavigation = this.profileNavigation.bind(this)
    this.logoutNavigation = this.logoutNavigation.bind(this)
  }

  homeNavigation(){
    this.props.history.push('/home')
  }

  profileNavigation() {
    this.props.history.push('/profile')
  }

  logoutNavigation() {
    this.props.history.push('/logout')
  }

  render(){
    return (
      <div className="homeNav">
        <nav className="navbar navbar-expand-lg navbar-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <button className="dropdown-item" onClick={() => {this.homeNavigation()}}>Home</button>
              </li>
              <li className="nav-item dropdown">
                <button className="dropdown-item" onClick={() => {this.profileNavigation()}}>Profile</button>
              </li>
              <li className="nav-item dropdown">
                <button className="dropdown-item" onClick={() => {this.logoutNavigation()}}>Logout</button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default HomeNavbar;

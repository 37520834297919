import { updateUserInfo } from '../signup/signupActions'
import api from '../apiRoutes'
export { getClubs } from '../home/homeActions'
export { GETAccessToken } from '../actions/generalActions'

export function updateUserProfile(values) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `password=${values.password}&token=${values.token}&productUpdates=${values.productUpdates}&feedback=${values.feedback}&userUuid=${values.userUuid}&favClub=${values.favClub}&contribution=${values.contribution}`
  }
  return dispatch => {
    return fetch(`${api}/user/updateUserProfile`, config)
      .then(response =>
        response.json().then(updateProfile => ({ updateProfile, response }))
      ).then(({ updateProfile, response }) =>  {
        dispatch(updateUserInfo(updateProfile))
      })
  }
}

import React, { Component } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import ErrorButtonOutlined from '../components/buttons/errorButtonOutlined'
import LoadingComponent from '../components/loading/loading'
import $ from 'jquery'

import './admin.css'
import '../styles/goals.css'

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      subreddit: "",
      test_subreddit: ""
    }
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
    this.submitApprovedGoal = this.submitApprovedGoal.bind(this)
    this.saveSubreddit = this.saveSubreddit.bind(this)
  }

  componentDidMount = () => {
    this.props.actions.setLoading()
    this.setState({
      subreddit: this.props.SelectedClub.club.subreddit,
      test_subreddit: this.props.SelectedClub.club.test_subreddit
    })
  }

  updateSubReddit = (ev) => {
    this.setState({
      subreddit: ev.target.value
    })
  }

  updateTestSubReddit = (ev) => {
    this.setState({
      test_subreddit: ev.target.value
    })
  }

  pauseVideo = ({ev}) => {
    ev.preventDefault()
    $('video').trigger('pause');
  }

  saveSubreddit = () => {
    const club_uuid = this.props.SelectedClub.selected_uuid
    const subreddit = this.state.subreddit
    const test_subreddit = this.state.test_subreddit
    this.props.actions.updateSubreddit({ club_uuid, subreddit, test_subreddit})
  }

  goalView = ({goalUrl, goalList, matchId, keyName, clubName, SelectedClub, User}) => {
    if(!goalUrl){
      return (
        <form onSubmit={(event) => {this.onSubmitHandler({event, matchId, keyName, clubName, clubUuid: SelectedClub.club.uuid, userUuid: User.uuid})}}>
          <input type="file" name="file" onChange={this.onChangeHandler}/>
          <button type="submit" className="btn btn-success btn-block">Upload</button>
        </form>
      )
    } else {
      let url = goalUrl.split('.').pop()
      if (url === 'mp4'){
        return (
          <video controls alt={goalList.uuid}>
            <source src={goalUrl} />
            Your browser doesn't support playing videos
          </video>
        )
      } else {
        return (
          <img src={goalUrl} alt={goalList.uuid} />
        )
      }
    }
  }

  submitApprovedGoal({uuid, token}){
    this.props.actions.approveSubmission({uuid, token})
  }

  // Need to create a display for submission on each goal
  goalAdminView = ({goalSubmission, token}) => {
    const goalSubmissionDisplay = []
    for(let i = 0; i < goalSubmission.length; i++){
      let goalSubmissionUrl = goalSubmission[i].url
      let url = goalSubmissionUrl.split('.').pop()
      if (url === 'mp4'){
        if(goalSubmission[i].approved === true){
          goalSubmissionDisplay.push (
            <div className="goalSubmission goalSubmission-approved" key={goalSubmission[0].uuid}>
              <div className="approval row">
                <i className="fas fa-thumbs-up"></i>
                <p>Approved Selection</p>
              </div>
              
              <div className={goalSubmission[i].uuid + " display-video"}>
                <div className="goalSubmission-video">
                  <video className="col" controls alt={goalSubmission[0].uuid}>
                    <source src={goalSubmissionUrl} />
                    Your browser doesn't support playing videos
                  </video>
                </div>
                <div className="goalSubmission-buttons row">
                  <div className="col">
                    <Button variant="contained" className="col" onClick={() => {this.submitApprovedGoal({uuid: goalSubmission[i].uuid, token})}} id={goalSubmission[i].uuid + " goalSubmission-approve"} color="primary">
                      Approve
                    </Button>
                  </div>
                  <div className="col">
                    <ErrorButtonOutlined
                      buttonId = {goalSubmission[i].uuid + " goalSubmission-remove"}
                      buttonText = "Remove"
                      uuid = {goalSubmission[i].uuid}
                      token = {token}
                      action = {this.props.actions.removeUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        } else {
          goalSubmissionDisplay.push (
            <div className="goalSubmission">
              <div className={goalSubmission[i].uuid + " display-video"}>
                <div className="goalSubmission-video">
                  <video className="col" controls alt={goalSubmission[0].uuid}>
                    <source src={goalSubmissionUrl} />
                    Your browser doesn't support playing videos
                  </video>
                </div>
                <div className="goalSubmission-buttons row">
                  <div className="col">
                    <Button variant="contained" className="col" onClick={() => {this.submitApprovedGoal({uuid: goalSubmission[i].uuid, token})}} id={goalSubmission[i].uuid + " goalSubmission-approve"} color="primary">
                      Approve
                    </Button>
                  </div>
                  <div className="col">
                    <ErrorButtonOutlined
                      buttonId = {goalSubmission[i].uuid + " goalSubmission-remove"}
                      buttonText = "Remove"
                      uuid = {goalSubmission[i].uuid}
                      token = {token}
                      action = {this.props.actions.removeUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      } else {
        goalSubmissionDisplay.push (
          <div className="goalSubmission">
            <div className={goalSubmission[i].uuid + " display-video"}>
              <div className="goalSubmission-video">
                <img id="goalSubmission-gif" src={goalSubmissionUrl} alt={goalSubmission[0].uuid} />
                <div className="goalSubmission-buttons row">
                  <div className="col">
                    <Button variant="contained" className="col" onClick={() => {this.submitApprovedGoal({uuid: goalSubmission[i].uuid, token})}} id={goalSubmission[i].uuid + " goalSubmission-approve"} color="primary">
                      Approve
                    </Button>
                  </div>
                  <div className="col">
                    <ErrorButtonOutlined
                      buttonId = {goalSubmission[i].uuid + " goalSubmission-remove"}
                      buttonText = "Remove"
                      uuid = {goalSubmission[i].uuid}
                      token = {token}
                      action = {this.props.actions.removeUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    }
    return goalSubmissionDisplay
  }

  goalListView = ({goalList, token}) => {
    const goalViewDisplay = []
    if(!goalList) { return goalViewDisplay }
    for(let j =0; j<goalList.length; j++){
      
      let goalSubmission = goalList[j].goalsubmission
      if(!goalSubmission){
        goalViewDisplay.push(
          <div key={goalList[j].uuid}>
            <h3>Goal Scorer: {goalList[j].goalname}</h3>
            <h3>Assist: {goalList[j].assistname}</h3>
            <p>No Goals Submitted!</p>
          </div>
        )
      } else {
        goalViewDisplay.push(
          <div className={"goal " + goalList[j].uuid} key={goalList[j].uuid}>
            <h3>Goal Scorer: {goalList[j].goalname}</h3>
            <h3>Assist: {goalList[j].assistname}</h3>
            <div className="goalSubmission-display row">
              {this.goalAdminView({goalSubmission, token})}
            </div>
          </div>
        )
      }
    }
    return goalViewDisplay
  }

  matchView = ({matchViewList, actions, SelectedClub, User, token}) => {
    const list = matchViewList
    let matchViewDisplay = []
    // eslint-disable-next-line
    let count = 1
    for(let i = 0; i < list.length; i++){
      let subject = list[i].subject
      let uuid = list[i].uuid
      let season = list[i].season
      let competitiontype = list[i].competitiontype
      let hometeam = list[i].hometeam
      let awayteam = list[i].awayteam
      let status = list[i].status
      let matchid = list[i].matchid
  
      matchViewDisplay.push(
        <div className="card" key={matchid}>
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button className="btn btn-link" id={uuid} data-toggle="collapse" data-target={"#collapse " + uuid} aria-expanded="true" aria-controls={"collapse " + uuid} onClick={(ev) => {this.pauseVideo({ev})}}>
                {subject} ({status})
              </button>
            </h5>
          </div>
          <div id={"collapse " + uuid} className="collapse" aria-labelledby="headingOne" data-parent="#admin-accordion">
            <div className="card-body">
              <h2>Season: {season}. {competitiontype} match between {hometeam} and {awayteam}</h2>
              <div className="goals">
                {this.goalListView({goalList: list[i].goals, actions, matchId: matchid, SelectedClub, User, token})}
              </div>
            </div>
          </div>
        </div>
      )
      count++
    }
    return matchViewDisplay
  }

  seasonView = ({matchViewList, actions, SelectedClub, User, token}) => {
    const list = matchViewList
    let seasonViewDisplay = []
    let count = 1
    for(let i = 0; i < list.length; i++){
      let season = list[i].season
  
      seasonViewDisplay.push(
        <div className="card" key={season}>
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button className="btn btn-link" id={season} data-toggle="collapse" data-target={"#season"+ count} aria-expanded="true" aria-controls={"collapse" + count}>
                {season} Season
              </button>
            </h5>
          </div>
          <div id={"season" + count} className="collapse" aria-labelledby="headingOne" data-parent="#season-accordion">
            <div className="card-body">
              <div className="matchs">
                {this.matchView({matchViewList: matchViewList[i].matchs, actions, SelectedClub, User, token})}
              </div>
            </div>
          </div>
        </div>
      )
      count++
    }
    return seasonViewDisplay
  }

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }

  onSubmitHandler = ({event, matchId, keyName, clubName, clubUuid, userUuid}) => {
    event.preventDefault();
    const data = new FormData();
    data.append('file', this.state.selectedFile);
    data.append('keyName', keyName)
    data.append('matchId', matchId)
    data.append('clubName', clubName)
    data.append('clubuuid', clubUuid)
    data.append('useruuid', userUuid)
    let token = localStorage.getItem('token')
    this.props.actions.uploadVideo({data, token})
  }
  
  render(){
    const { SelectedClub, User, MatchList, Loading, actions } = this.props
    const token = localStorage.getItem('token')
    if(Loading.loading === true){
      return (
        <LoadingComponent />
      )
    } else {
      return (
        <div className="admin-workspace">
          <div className="admin-logo row">
            <img className="col-md-12" id="admin-selection-logo" alt="admin-club-logo" src={SelectedClub.club.mediumlogo} />
            <div className={"admin "+ SelectedClub.club.name}>
              <h3>{SelectedClub.club.name}</h3>
            </div>
          </div>
          <form id="admin-subreddit-form">
            <Tooltip title="This is the main subreddit for your club. Please exclude the /r/" placement="top-start">
              <div className="admin-subreddit-workspace" id="admin-subreddit">
                <div className="live-subreddit">
                  <h4>Subreddit:</h4>
                  <input 
                    placeholder="Club Subreddit"
                    defaultValue = { this.state.subreddit }
                    onKeyUp ={ (ev) => {this.updateSubReddit(ev)}}
                  />
                </div>
              </div>
            </Tooltip>
            <Tooltip title="This is the subreddit where you want to test various queries for the bot. Please exclude the /r/" placement="top-start">
              <div className="admin-test-subreddit-workspace" id="admin-subreddit">
                <div className="test-subreddit">
                  <h4>Test Subreddit:</h4>
                  <input 
                    placeholder="Club Test Subreddit"
                    defaultValue = { this.state.test_subreddit }
                    onKeyUp ={ (ev) => {this.updateTestSubReddit(ev)}}
                  />
                </div>
              </div>
            </Tooltip>
            <Button variant="contained" className="col" onClick={this.saveSubreddit} id="admin-save-subreddit-info" color="primary">
              Save Subreddit Information
            </Button>
          </form>
          <div id="admin-accordion">
            {this.seasonView({matchViewList: MatchList.list, actions, SelectedClub, User, token})}
          </div>
        </div>
      );
    }
  }
}

export default Admin;

import api from '../apiRoutes'

export function updateResetPasswordMessage(resetPasswordMessage){
  return {
    type: 'UPDATE_RESET_PASSWORD_MESSAGE',
    resetPasswordMessage
  }
}

export function clearResetPasswordMessages(){
  return {
    type: 'CLEAR_RESET_PASSWORD_MESSAGE'
  }
}

export function resetPasswordPendingStatus(){
  return {
    type: 'RESET_PASSWORD_PENDING_STATUS'
  }
}

export function resetPasswordCompleteStatus(){
  return {
    type: 'RESET_PASSWORD_COMPLETE_STATUS'
  }
}

export function resetPasswordClearStatus(){
  return {
    type: 'RESET_PASSWORD_CLEAR_STATUS'
  }
}

export function forgotPassword({email}) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `email=${email}`
  }
  return dispatch => {
    return fetch(`${api}/email/forgot_password`, config)
      .then(response =>
        response.json().then(forgotPassword => ({ forgotPassword, response }))
      ).then(({ forgotPassword, response }) =>  {
        dispatch(updateResetPasswordMessage(forgotPassword.message))
      })
  }
}
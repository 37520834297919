import React, { Component } from 'react';
import {  Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import LoadingComponent from '../components/loading/loading';
import $ from 'jquery';

import './club.css'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Club extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      fileSizeError: false,
      successfulUpload: false
    }
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
    this.uploadSizeError = this.uploadSizeError.bind(this)
    this.successfulUpload = this.successfulUpload.bind(this)
  }

  componentDidMount = () => {
    this.props.actions.setLoading()
  }

  goalView = ({goalUrl, goalList, matchId, keyName, clubName, SelectedClub, User}) => {
    if(!goalUrl){
      return (
        <form onSubmit={(event) => {this.onSubmitHandler({event, matchId, keyName, clubName, clubUuid: SelectedClub.club.uuid, userUuid: User.uuid})}}>
          <input type="file" name="file" onChange={this.onChangeHandler}/>
          <button type="submit" className="btn btn-success btn-block">Upload</button>
        </form>
      )
    } else {
      let url = goalUrl.split('.').pop()
      if (url === 'mp4'){
        return (
          <video className="goal-view-display" controls alt={goalList.uuid}>
            <source src={goalUrl} />
            Your browser doesn't support playing videos
          </video>
        )
      } else {
        return (
          <img className="goal-view-display" src={goalUrl} alt={goalList.uuid} />
        )
      }
    }
  }

  assistNamePresent = ({goalList, matchId, SelectedClub, User}) => {
    const goalUrl = goalList.url
    const clubName = SelectedClub.club.name
    const keyName = goalList.uuid
    if(!goalList.assistname && goalList.pen){
      return (
        <div className="goal" key={goalList.uuid}>
          <h3>Goal Scorer: {goalList.goalname}</h3>
          <h3>Pen: True</h3>
          <div className="goal-view">
            {this.goalView({goalUrl, goalList, matchId, keyName, clubName, SelectedClub, User})}
          </div>
        </div>
      )
    } else if(goalList.assistname && !goalList.pen){
      return (
        <div className="goal" key={goalList.uuid}>
          <h3>Goal Scorer: {goalList.goalname}</h3>
          <h3>Assist: {goalList.assistname}</h3>
          <div className="goal-view">
            {this.goalView({goalUrl, goalList, matchId, keyName, clubName, SelectedClub, User})}
          </div>
        </div>
      )
    } else {
      return (
        <div className="goal" key={goalList.uuid}>
          <h3>Goal Scorer: {goalList.goalname}</h3>
          <div className="goal-view">
            {this.goalView({goalUrl, goalList, matchId, keyName, clubName, SelectedClub, User})}
          </div>
        </div>
      )
    }
  }

  goalListView = ({goalList, actions, matchId, SelectedClub, User}) => {
    const goalViewDisplay = []
    if(!goalList) { return goalViewDisplay }
    for(let j =0; j<goalList.length; j++){
      let view = this.assistNamePresent({goalList: goalList[j], actions, matchId, SelectedClub, User})
      goalViewDisplay.push(view)
    }
    return goalViewDisplay
  }

  createGoalRequest = ({ uuid, token }) => {
    this.props.actions.createGoalRequest({uuid, token})
  }
  
  pauseVideo = ({ev}) => {
    ev.preventDefault()
    $('video').trigger('pause');
  }

  matchView = ({matchViewList, actions, SelectedClub, User}) => {
    let token = localStorage.getItem('token')
    const list = matchViewList
    let matchViewDisplay = []
    // eslint-disable-next-line
    let count = 1
    for(let i = 0; i < list.length; i++){
      let subject = list[i].subject
      let uuid = list[i].uuid
      let season = list[i].season
      let competitiontype = list[i].competitiontype
      let hometeam = list[i].hometeam
      let awayteam = list[i].awayteam
      let status = list[i].status
      let matchid = list[i].matchid
  
      matchViewDisplay.push(
        <div className="card" key={uuid}>
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button className="btn btn-link" id={uuid} data-toggle="collapse" data-target={"#collapse " + uuid} aria-expanded="true" aria-controls={"collapse " + uuid} onClick={(ev) => {this.pauseVideo({ev})}}>
                {subject} ({status})
              </button>
            </h5>
          </div>
          <div id={"collapse " + uuid} className="collapse" aria-labelledby="headingOne" data-parent="#club-accordion">
            <div className="card-body">
              Season: {season}. {competitiontype} match between {hometeam} and {awayteam}
              <button className="create-goal-request btn" id={uuid} onClick={() => { this.createGoalRequest({ uuid, token })}}>
                Find Goals
              </button>
              <div className="goals">
                {this.goalListView({goalList: list[i].goals, actions, matchId: matchid, SelectedClub, User})}
              </div>
            </div>
          </div>
        </div>
      )
      count++
    }
    return matchViewDisplay
  }

  seasonView = ({matchViewList, actions, SelectedClub, User, token}) => {
    const list = matchViewList
    let seasonViewDisplay = []
    let count = 1
    for(let i = 0; i < list.length; i++){
      let season = list[i].season
  
      seasonViewDisplay.push(
        <div className="card" key={season}>
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button className="btn btn-link" id={season} data-toggle="collapse" data-target={"#season"+ count} aria-expanded="true" aria-controls={"collapse" + count}>
                {season} Season
              </button>
            </h5>
          </div>
          <div id={"season" + count} className="collapse" aria-labelledby="headingOne" data-parent="#season-accordion">
            <div className="card-body">
              <div className="matchs">
                {this.matchView({matchViewList: matchViewList[i].matchs, actions, SelectedClub, User, token})}
              </div>
            </div>
          </div>
        </div>
      )
      count++
    }
    return seasonViewDisplay
  }

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
      fileSizeError:false
    })
  }

  onSubmitHandler = ({event, matchId, keyName, clubName, clubUuid, userUuid}) => {
    event.preventDefault();
    const data = new FormData();
    const file = this.state.selectedFile
    if(file.size > 250000000){ 
      this.setState({
        fileSizeError:true
      })
    }
    data.append('file', this.state.selectedFile);
    data.append('keyName', keyName)
    data.append('matchId', matchId)
    data.append('clubName', clubName)
    data.append('clubuuid', clubUuid)
    data.append('useruuid', userUuid)
    let token = localStorage.getItem('token')
    this.props.actions.uploadVideo({data, token})
    this.setState({
      successfulUpload: true
    })
  }

  handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({successfulUpload: false});
  };
  
  handleErrorClose = (event, reason) => {
    
    if (reason === 'clickaway') {
      return;
    }
    this.setState({fileSizeError: false});
  };

  uploadSizeError = () => {
    if(this.state.fileSizeError === true){
      return (
        <div className="reset-password-error-message">
          <Snackbar open={true} autoHideDuration={2000} onClose={this.handleErrorClose}>
            <Alert onClose={this.handleErrorClose} severity="error">
              Upload file size is to large
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }

  successfulUpload = () => {
    if(this.state.successfulUpload === true){
      return (
        <div className="reset-password-error-message">
          <Snackbar open={true} autoHideDuration={2000} onClose={this.handleSuccessClose}>
            <Alert onClose={this.handleSuccessClose} severity="success">
              Successfully upload file
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }

  closeRequestGoalsSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.actions.clearRequestGoals()
  }

  requestGoals = () => {
    if(this.props.SelectedClub.request_goals === "Goals already created"){
      return (
        <div className="reset-password-error-message">
          <Snackbar open="open" autoHideDuration={2000} onClose={this.closeRequestGoalsSnackbar}>
            <Alert onClose={this.closeRequestGoalsSnackbar} severity="success">
              Goals already created
            </Alert>
          </Snackbar>
        </div>
      )
    } else if (this.props.SelectedClub.request_goals === "Creating goals, check back later"){
      return (
        <div className="reset-password-error-message">
          <Snackbar open="open" autoHideDuration={2000} onClose={this.closeRequestGoalsSnackbar}>
            <Alert onClose={this.closeRequestGoalsSnackbar} severity="success">
              Creating goals, check back later
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }
  
  render(){
    const { SelectedClub, User, MatchList, Loading, actions } = this.props
    if(Loading.loading === true){
      return (
        <LoadingComponent />
      )
    } else {
      return (
        <div className="club-workspace">
          <div className="club-section row">
            <img className="col-md-12" id="club-selected-logo" alt={`${SelectedClub.club.name} logo`} src={SelectedClub.club.mediumlogo} />
            <div className="club-name">
              <h3>{SelectedClub.club.name}</h3>
            </div>
          </div>
          <div id="club-accordion">
            {this.seasonView({matchViewList: MatchList.list, actions, SelectedClub, User})}
          </div>
          {this.uploadSizeError()}
          {this.successfulUpload()}
          {this.requestGoals()}
        </div>
      );
    }
  }
}

export default Club;

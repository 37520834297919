import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import './resetPassword.css'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ResetPassword extends Component  {
  constructor() {
    super();
    this.state = {
      password: '',
      confirmPassword: '',
      error: false,
      errorMessage: `The passwords don't match`
    }
    this.submit = this.submit.bind(this)
    this.error = this.error.bind(this)
    this.triggerResetPassword = this.triggerResetPassword.bind(this)
  }

  componentDidMount() {
    this.props.actions.clearResetPasswordMessages()
    this.props.actions.resetPasswordClearStatus()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.User.resetPasswordStatus === 'Complete' && !this.props.User.resetPasswordMessage){
      this.props.history.push('/login')
    }
  }

  componentWillUnmount() {
    this.props.actions.resetPasswordClearStatus()
  }
  
  
  
  triggerResetPassword(values){
    this.props.actions.resetPassword(values)
  }

  submit({password, confirmPassword}){
    if(password === confirmPassword){
      this.setState({error: false})
      // Sumit the password reset
      const href = window.location.href
      const split = href.split('/reset/')
      const token = split[1]
      const values = { password, token }
      this.triggerResetPassword(values)
    } else {
      this.setState({error: true})
      // Fire an error
    }
  }

  error(){
    if(this.state.error === true){
      return (
        <div className="reset-password-error-message">
          <Snackbar open="open" autoHideDuration={2000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity="error">
              {this.state.errorMessage}
            </Alert>
          </Snackbar>
        </div>
      )
    } else if (this.props.User.resetPasswordMessage === 'Password Reset Token is no longer valid. Please make a new forgot password request.' ) {
      return (
        <div className="reset-password-error-message">
          <Snackbar open='open' autoHideDuration={2000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity="error">
              {this.props.User.resetPasswordMessage}
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }
  
  render (){
    const buttonText = "reset Password"
    const buttonId = "reset-password-form-button-submit"
    return (
      <div className="reset-password-workspace row">
        <div className="reset-password-application-name col-12">
          <h2>Reddit Goalbot</h2>
        </div>
        <div className="reset-password-raised-area">
          <h3>Change Password</h3>
          <p>Please enter a new password.</p>
          <form >
          <div className="reset-password-form row ">
              <div className="reset-password-form-password">
                <input className="reset-password col-12" type="password" onKeyUp={(e) => this.setState({password: e.target.value, error: false})} placeholder="New Password" />
              </div>
              <div className="reset-password-form-password-confirm">
                <input className="reset-password-confirm col-12" type="password" onKeyUp={(e) => this.setState({confirmPassword: e.target.value, error: false})} placeholder="Confirm New Password" />
              </div>
              <Button variant="contained" id={buttonId} color="primary" onClick={() => this.submit({ password: this.state.password, confirmPassword: this.state.confirmPassword })}>
                {buttonText}
              </Button>
            </div>
          </form>
        </div>
        {this.error()}
      </div>
    );
  }
  
  
}

export default ResetPassword;

import assign from 'object-assign'

function MatchList(state = {
  list: [],
}, action) {
    switch (action.type) {
        case "UPDATE_MATCH_LIST":
          return assign({}, state, {
            list: action.matchList,
          })
        case "CLEAR_MATCH_LIST":
          return assign({}, state, {
            list: [],
          })
        default:
          return state
    }
}

export default MatchList

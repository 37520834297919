import React, { Component } from 'react';
import { Snackbar } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import './signup.css'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Signup extends Component  {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      error: false,
    }
    this.submit = this.submit.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.Signup.error && this.state.error === false){
      this.setState({error: true})
    }
  }

  async submit({email, password}){
    const { actions } = this.props
    const refresh_token = localStorage.getItem('refresh_token')
    await actions.GETAccessToken({ refresh_token: refresh_token})
    const access_token = localStorage.getItem('access_token')
    const values = { email, password, access_token, refresh_token }
    actions.clearSignupError()
    actions.signupApi(values)
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.actions.clearSignupError()
    this.setState({error: false});
  };

  error(){
    if(this.props.Signup.error){
      return (
        <div className="signup-error-message">
          <Snackbar open="open" autoHideDuration={6000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity="error">
              {this.props.Signup.error}
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }
  
  render (){
    const buttonText = "Submit"
    const buttonId = "signup-form-button-submit"
    return (
      <div className="signup-workspace row">
        <div className="signup-application-name col-12">
          <h2>Reddit Goalbot</h2>
        </div>
        <div className="signup-raised-area">
          <h3>Signup</h3>
          <form >
          <div className="signup-form row ">
              <div className="signup-form-username">
                <input className="signup-username col-12" type="email" onKeyUp={(e) => this.setState({email: e.target.value})} placeholder="Email" />
              </div>
              <div className="signup-form-password">
                <input className="signup-password col-12" type="password" onKeyUp={(e) => this.setState({password: e.target.value})} placeholder="Password" />
              </div>
              <Button variant="contained" id={buttonId} color="primary" onClick={() => this.submit({ email: this.state.email, password: this.state.password })}>
                {buttonText}
              </Button>
            </div>
          </form>
          <p>By submitting this form you opt-in to receiving product update and marketing emails in the future. You can opt-out at anytime in your user profile.</p>
        </div>
        {this.error()}
      </div>
    );
  }
  
  
}

export default Signup;

import assign from 'object-assign'

function Loading(state = {
    loading: false,
}, action) {
    switch (action.type) {
        case "SET_LOADING":
            return assign({}, state, {
                loading: true
            })
        case "RESET_LOADING":
            return assign({}, state, {
                loading: false,
            })
        default:
            return state
    }
}

export default Loading

import api from '../apiRoutes'

const GOALBOT_API_UUID = process.env.REACT_APP_GOALBOT_API_UUID

export const GETRefreshToken = () => {
  let config = {
    method: 'GET',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
  }
  return dispatch => {
    return fetch(`${api}/authentication/refresh_token/integration_uuid/${GOALBOT_API_UUID}`, config)
      .then(response =>
        response.json()
        .then(authentication => ({ authentication, response }))
      ).then(({ authentication, response }) =>  {
        localStorage.setItem('refresh_token', authentication.refreshToken)
      })
  }
}

export const GETAccessToken = ({ refresh_token }) => {
  let config = {
    method: 'GET',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
  }

  return dispatch => {
    return fetch(`${api}/authentication/access_token/integration_uuid/${GOALBOT_API_UUID}/refresh_token/${refresh_token}`, config)
      .then(response =>
        response.json()
        .then(authentication => ({ authentication, response }))
      ).then(({ authentication, response }) =>  {
        localStorage.setItem('access_token', authentication.accessToken)
      })
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

import * as actionCreators from './forgotPasswordActions'
import ForgotPassword from './forgotPassword'

class ForgotPasswordContainer extends Component {
  
  render(){
    const { actions, User } = this.props
    ReactGA.pageview('/forgot');
    return (
      <div className="signup-container">
        <ForgotPassword
          User = { User }
          actions = { actions }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.User
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default ForgotPasswordContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer))
